import React from "react"
import BandsBackdrop from "./bands-backdrop"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import concerto from "../images/logos/concerto.svg"
import genesys from "../images/logos/genesys.svg"
import JLL from "../images/logos/jll.svg"
import TTEC from "../images/logos/ttec.svg"
import Wazoku from "../images/logos/wazoku.svg"
import IBM from "../images/logos/IBM_Partner_Plus_platinum_partner_mark_pos_platinum_RGB.svg"
import Bytes from "../images/logos/bytes-v2.png"
import Microsoft from "../images/logos/microsoft.png"
import HIDGlobal from "../images/logos/hidglobal.svg"
import AWS from "../images/logos/aws.svg"
import BT from "../images/logos/bt.svg"
import Nextiva from "../images/logos/nextiva.svg"
import WazokuOneBigThing from "../images/logos/wazoku-one-big-thing-h.png"
import NetCompany from "../images/logos/netcompany.svg"
import GovNews from "../images/logos/govnews.png"
import AwardCategory from "./award-category"

const AwardSponsors = ({children, gridColor}) => (
    <div>
        <h3 className="text-center my-5" style={{
            color: `#fff`,
            fontFamily: `Avenir`,
            fontWeight: `900`,
            fontSize: `3.5rem`,
        }}>Sponsors</h3>
        <p className="text-center" style={{
            color: `#fff`,
        }} hidden>Public Finance Awards sponsorship provides the unmissable opportunity to create exposure for your brand, as well as the opportunity to advertise your products and services to 14,000 CIPFA members and the wider public finance community.</p>

        <br />

        <div className="row justify-content-center">
          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Improving Spaces" gridColor="#d23464" icon="computer-desk">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.concerto.co.uk/" target="_blank"><img src={concerto} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Future Focused and Sustainable Property" gridColor="#30c4bf" icon="office">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.jll.co.uk" target="_blank"><img src={JLL} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Innovation as a Service" gridColor="#30c4bf" icon="innovation">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.wazoku.com/" target="_blank"><img src={WazokuOneBigThing} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Digital Innovation as a Service" gridColor="#30c4bf" icon="digital-innovation">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.wazoku.com/" target="_blank"><img src={WazokuOneBigThing} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Harnessing the Value of Data" gridColor="#d23464" icon="binary">
              <p>Sponsored By</p>
              <OutboundLink href="https://netcompany.com/" target="_blank"><img src={NetCompany} className="img-fluid py-2" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Customer Experience" gridColor="#d23464" icon="customer-experience">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.genesys.com/en-gb" target="_blank"><img src={genesys} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
              <OutboundLink href="https://ttecdigital.com/" target="_blank"><img src={TTEC} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="User Experience" gridColor="#30c4bf" icon="user-experience">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.hidglobal.com/" target="_blank"><img src={ HIDGlobal } className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5" hidden>
            <AwardCategory title="Smarter Workflows" gridColor="#30c4bf" icon="workflows">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.concerto.co.uk/" target="_blank"><img src={concerto} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Digital Inclusion" gridColor="#d23464" icon="inclusion">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.genesys.com/en-gb" target="_blank"><img src={genesys} className="img-fluid mr-3" style={{ maxHeight: `3rem`}} /></OutboundLink>
              <OutboundLink href="https://business.bt.com/public-sector" target="_blank"><img src={BT} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Automation, AI and Machine Learning" gridColor="#d23464" icon="brain">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2 mr-2" style={{ maxHeight: `3rem`}} /></OutboundLink>
              <OutboundLink href="https://www.bytes.co.uk/partners/vendor-partners/ibm" target="_blank"><img src={IBM} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Building Collaborative Communities" gridColor="#30c4bf" icon="circle-people">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2 mr-2" style={{ maxHeight: `3rem`}} /></OutboundLink>
              <OutboundLink href="https://aws.amazon.com/" target="_blank"><img src={ AWS } className="img-fluid p-2" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Small-scale, Big Impact" gridColor="#30c4bf" icon="inward-arrows-square">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.gov.news/" target="_blank"><img src={GovNews} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Putting People First" gridColor="#d23464" icon="inspire">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.nextiva.com/" target="_blank"><img src={Nextiva} className="img-fluid py-1" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5" hidden>
            <AwardCategory title="Championing Continuous Improvement" gridColor="#d23464" icon="three-up-arrows">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.concerto.co.uk/" target="_blank"><img src={concerto} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5" hidden>
            <AwardCategory title="Outstanding Smarter Working Leadership" gridColor="#30c4bf" icon="leader">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.concerto.co.uk/" target="_blank"><img src={concerto} className="img-fluid" style={{ maxHeight: `3rem`}} /></OutboundLink>
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Special Recognition" gridColor="#30c4bf" icon="star">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2 mr-4" style={{ maxHeight: `3rem`}} /></OutboundLink>
              <img src={Microsoft} className="img-fluid p-2" style={{ maxHeight: `3rem`}} />
            </AwardCategory>
          </div>

          <div className="col-sm-6 col-lg-6 mb-5">
            <AwardCategory title="Beyond Smarter Working" gridColor="#d23464" icon="light-bulb-cog">
              <p>Sponsored By</p>
              <OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2 mr-4" style={{ maxHeight: `3rem`}} /></OutboundLink>
              <img src={Microsoft} className="img-fluid p-2" style={{ maxHeight: `3rem`}} />
            </AwardCategory>
          </div>
        </div>








        <div className="row justify-content-center text-center" hidden>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.concerto.co.uk/" target="_blank"><img src={concerto} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Improving Spaces</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.concerto.co.uk/" target="_blank"><img src={concerto} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Harnessing the Value of Data</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.nextiva.com/" target="_blank"><img src={Nextiva} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Putting People First</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <div className="row">
                    <div className="col"><OutboundLink href="https://www.genesys.com/en-gb" target="_blank"><img src={genesys} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink></div>
                    <div className="col"><OutboundLink href="https://ttecdigital.com/" target="_blank"><img src={TTEC} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink></div>
                  </div>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Customer Experience</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <div className="row">
                    <div className="col"><OutboundLink href="https://www.genesys.com/en-gb" target="_blank"><img src={genesys} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink></div>
                    <div className="col"><OutboundLink href="https://business.bt.com/public-sector" target="_blank"><img src={BT} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink></div>
                  </div>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Digital Inclusion</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <div className="row">
                    <div className="col"><OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2" /></OutboundLink></div>
                    <div className="col"><OutboundLink href="https://www.bytes.co.uk/partners/vendor-partners/ibm" target="_blank"><img src={IBM} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink></div>
                  </div>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Automation, AI and Machine Learning</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.jll.co.uk" target="_blank"><img src={JLL} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Future Focused and Sustainable Property</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4 d-flex justify-content-center align-items-center" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.wazoku.com/" target="_blank"><img src={WazokuOneBigThing} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Innovation as a Service</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4 d-flex justify-content-center align-items-center" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.wazoku.com/" target="_blank"><img src={WazokuOneBigThing} className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Digital Innovation as a Service</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://www.hidglobal.com/" target="_blank"><img src={ HIDGlobal } className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">User Experience</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <OutboundLink href="https://aws.amazon.com/" target="_blank"><img src={ AWS } className="img-fluid" style={{ maxHeight: `4rem`}} /></OutboundLink>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Small-scale, Big Impact</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <div className="row">
                    <div className="col"><OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2" /></OutboundLink></div>
                    <div className="col"><img src={Microsoft} className="img-fluid p-2" style={{ maxHeight: `4rem`}} /></div>
                  </div>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Special Recognition</p>
              </div>
            </BandsBackdrop>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <BandsBackdrop position="-0.5rem">
              <div className="bg-white">
                <div className="p-4" style={{ minHeight: `7rem`}}>
                  <div className="row">
                    <div className="col"><OutboundLink href="https://www.bytes.co.uk/" target="_blank"><img src={Bytes} className="img-fluid p-2" /></OutboundLink></div>
                    <div className="col"><img src={Microsoft} className="img-fluid p-2" style={{ maxHeight: `4rem`}} /></div>
                  </div>
                </div>
                <p><strong>Sponsoring</strong></p>
                <p className="px-3 pb-3">Beyond Smarter Working</p>
              </div>
            </BandsBackdrop>
          </div>
      </div>
    </div>
)

AwardSponsors.defaultProps = {
    gridColor: `#d23464`
  }

export default AwardSponsors
